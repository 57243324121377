<script>
export default {
  name: "ImprintPage"
}
</script>

<template>
  <div id="imprint" style="min-height: 80vh;">
    <h1 class="text-center main-heading">{{ heading }}</h1>

    <div class="row">
      <div class="col-lg-6 mx-auto text-center">
        <h4>{{ $t('imprint.address') }}</h4>
        <p>
          Max Schlosser<br>
          Großbrösern Nr. 4<br>
          02627 Radibor
        </p>

        <h4 class="mt-5">{{ $t('imprint.mailHeading') }}</h4>
<!--        <p>-->
<!--          <b>General Inquiries:</b><br/>hello@schlosser.dev-->
<!--        </p>-->
        <p>
<!--          <b>{{ $t('imprint.mailInfo') }}</b><br/>contact@schlosser.dev-->
          <b>{{ $t('imprint.mailInfo') }}</b><br/>kontakt@max-schlosser.de
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 mx-auto">
        <h4 class="mt-5">{{ $t('imprint.tosTitle') }}</h4>

        <h5>1. Terms</h5>

        <p>By accessing this Website, accessible from https://schlosser.dev, you are agreeing to be bound by
          these Website Terms and Conditions of Use and agree that you are responsible for the agreement with
          any applicable local laws. If you disagree with any of these terms, you are prohibited from
          accessing this site. The materials contained in this Website are protected by copyright and trade
          mark law.</p>

        <h5>2. Use License</h5>

        <p>Permission is granted to temporarily download one copy of the materials on schlosser.dev's Website
          for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer
          of title, and under this license you may not:</p>

        <ul>
          <li>modify or copy the materials;</li>
          <li>use the materials for any commercial purpose or for any public display;</li>
          <li>attempt to reverse engineer any software contained on schlosser.dev's Website;</li>
          <li>remove any copyright or other proprietary notations from the materials; or</li>
          <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
        </ul>

        <p>This will let schlosser.dev to terminate upon violations of any of these restrictions. Upon
          termination, your viewing right will also be terminated and you should destroy any downloaded
          materials in your possession whether it is printed or electronic format. These Terms of Service has
          been created with the help of the <a href="https://www.termsofservicegenerator.net">Terms Of Service
            Generator</a>.</p>

        <h5>3. Disclaimer</h5>

        <p>All the materials on schlosser.dev’s Website are provided "as is". schlosser.dev makes no warranties,
          may it be expressed or implied, therefore negates all other warranties. Furthermore, schlosser.dev
          does not make any representations concerning the accuracy or reliability of the use of the materials
          on its Website or otherwise relating to such materials or any sites linked to this Website.</p>

        <h5>4. Limitations</h5>

        <p>schlosser.dev or its suppliers will not be hold accountable for any damages that will arise with the
          use or inability to use the materials on schlosser.dev’s Website, even if schlosser.dev or an
          authorize representative of this Website has been notified, orally or written, of the possibility of
          such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of
          liability for incidental damages, these limitations may not apply to you.</p>

        <h5>5. Revisions and Errata</h5>

        <p>The materials appearing on schlosser.dev’s Website may include technical, typographical, or
          photographic errors. schlosser.dev will not promise that any of the materials in this Website are
          accurate, complete, or current. schlosser.dev may change the materials contained on its Website at
          any time without notice. schlosser.dev does not make any commitment to update the materials.</p>

        <h5>6. Links</h5>

        <p>schlosser.dev has not reviewed all of the sites linked to its Website and is not responsible for the
          contents of any such linked site. The presence of any link does not imply endorsement by
          schlosser.dev of the site. The use of any linked website is at the user’s own risk.</p>

        <h5>7. Site Terms of Use Modifications</h5>

        <p>schlosser.dev may revise these Terms of Use for its Website at any time without prior notice. By
          using this Website, you are agreeing to be bound by the current version of these Terms and
          Conditions of Use.</p>

        <h5>8. Governing Law</h5>

        <p>Any claim related to schlosser.dev's Website shall be governed by the laws of de without regards to
          its conflict of law provisions.</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
#imprint {
  padding: 0 1rem;
}

@media only screen and (max-width: 991px) {
  h5 {
    font-size: 16pt;
  }

  p {
    font-size: 12pt;
  }
}
</style>