<script>
import PortfolioCard from "@/components/PortfolioCard.vue";
export default {
  components: {PortfolioCard},
  data() {
    return {
      currentCategory: 'ALL',
    }
  },

  methods: {
    chooseCategory(newCategory) {
      this.currentCategory = newCategory;
    }
  }
}
</script>

<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div id="portfolio">
    <h1 class="text-center normal-heading mb-3">Portfolio</h1>

<!--    <div class="btn-group my-5 mx-5">-->
<!--      <button type="button" @click="chooseCategory('ALL')" :class="{active: currentCategory === 'ALL' }"-->
<!--              class="btn btn-lg btn-primary mx-2">All</button>-->
<!--      <button type="button" @click="chooseCategory('SOFTWARE')" :class="{active: currentCategory === 'SOFTWARE' }"-->
<!--              class="btn btn-lg btn-primary mx-2">Software Projects</button>-->
<!--      <button type="button" @click="chooseCategory('EDUCATION')"-->
<!--              :class="{active: currentCategory === 'EDUCATION' }"-->
<!--              class="btn btn-lg btn-primary mx-2">Education</button>-->
<!--    </div>-->

    <div class="row mx-2 mb-5 justify-content-center">
      <PortfolioCard translation-key="book" img="book.png"
        link="https://www.rheinwerk-verlag.de/spieleentwicklung-mit-unity-das-umfassende-handbuch"/>
      <PortfolioCard translation-key="studisq" img="studisq.png" link="http://studisq.de/"/>
      <PortfolioCard translation-key="deinplugin" img="deinplugin.png" link="https://deinplugin.net/"/>
      <PortfolioCard translation-key="hsmwmobil" img="hsmwmobil.png" link="https://www.hs-mittweida.de/webs/hsmwmobil/funktionen"/>
      <PortfolioCard translation-key="ampguard" img="ampguard.png" link="https://store.steampowered.com/app/1852660/Ampguard/"/>
      <PortfolioCard translation-key="schloool" img="schloool-yt.png" link="https://www.youtube.com/channel/UCZXhOwVsAlawjxbnIcsIsAA"/>
      <PortfolioCard translation-key="bivieh" img="bivieh-yt.png" link="https://www.youtube.com/channel/UCoZtjPCrPugVD9Q7Fb3tJ6w"/>
<!--      <PortfolioCard translation-key="webcongress" img="web-congress.jpg" link="https://webentwicklerkongress.de/"/>-->
      <PortfolioCard translation-key="lokallabore" img="lokallabore.png" link="https://lokallabore.de/"/>
    </div>
  </div>

</template>

<style scoped>

</style>