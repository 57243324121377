export default {
    messages: {
        en: {
            experience: 'Experience',
            skills: 'Skills',
            softSkills: 'Soft Skills',
            hardSkills: 'Hard Skills',
            education: 'Education',

            experienceTimepoints: [
                {
                    start: '01/2024',
                    end: 'CURRENT',
                    title: 'Research associate',
                    subTitle: 'University of Applied Sciences Mittweida (Germany)',
                    text: [
                        'development of analysis software utilizing vehicle data from motorsport and civil scenarios',
                        'encorporation of artificial intelligence to classify driving behavior and driver profiles',
                        'transmission to virtual driving scenarios and car configurations in simulation software'
                    ]
                },
                {
                    start: '02/2022',
                    end: '12/2023',
                    title: 'Student research assistant (education and distributed computing)',
                    subTitle: 'University of Applied Sciences Mittweida (Germany)',
                    text: [
                        'research in the field of education in a university joint project, focus on digital skills',
                        'planning and implementation of a learning specific application used to improve digital skills',
                        'various tasks such as setting up distributed systems, developing web services and frontend structures'
                    ]
                },
                {
                    start: '02/2022',
                    end: '05/2022',
                    title: 'Internship',
                    subTitle: ' Institut für Mittelstandskooperation Mittweida',
                    text: [
                        'active development of mobile application used as university everyday companion',
                        'integration of research-related functions and interfaces',
                        'carrying out evaluations to improve the application',
                        'research on conception of e-learning activities',
                    ]
                },
                {
                    start: '02/2021',
                    end: '07/2021',
                    title: 'Student research assistent (database systems)',
                    subTitle: 'University of Applied Sciences Mittweida (Germany)',
                    text: [
                        'co-worker in conception, implementation and maintenance for both a service as well as a corresponding user interface',
                        'mostly focused on the frontend'
                    ]
                },
                {
                    start: '01/2020',
                    end: '12/2020',
                    title: 'Personal tutor',
                    subTitle: 'Easy-Tutor',
                    text: [
                        'personal tutor for children, teenagers and young adults.',
                        'main topics: computer science, mathematics'
                    ]
                },
                {
                    start: '08/2019',
                    end: '09/2019',
                    title: 'Internship (Development Services)',
                    subTitle: 'itelligence Global Managed Services',
                    text: [
                        'familiarization with the SAPUI5 framework as well as supportive tools (Git, WebIDE, GitLab)',
                        'implementation of specific client guided projects using the SAPUI5 framework'
                    ]
                },
            ],

            educationTimepoints: [
                {
                    start: '10/2022',
                    end: 'CURRENT',
                    title: 'Media Informatics and Interactive Entertainment (M.Sc.)',
                    subTitle: 'University of Applied Sciences Mittweida (Germany)',
                    text: [
                        'focus on development of applications and complex distributed systems',
                        'projects under interdisciplinary cooperation',
                        'intersections with scientific work and other topics such as AI or statistical analysis'
                    ]
                },
                {
                    start: '10/2019',
                    end: '09/2022',
                    title: 'Media Informatics and Interactive Entertainment (B.Sc.)',
                    subTitle: 'University of Applied Sciences Mittweida (Germany)',
                    text: [
                        'average grade: 1.1',
                        'focus on game development, software engineering as well as interdisciplinary cooperation',
                        'personal focus on informatics application',
                        'intersections with creative studies such as game design'
                    ]
                },
                {
                    start: '09/2011',
                    end: '07/2019',
                    title: 'Abitur (German qualification for attending University)',
                    subTitle: 'Philipp-Melanchthon-Gymnasium Bautzen',
                    text: [
                        'average grade: 1.4',
                        'participation in multiple tutoring programs as well as organization activity, such as our final school program.'
                    ]
                }
            ],

            softSkillItems: [
                {
                    name: 'Teamwork',
                    eval: 'highly collaborative',
                    amount: 98,
                },
                {
                    name: 'Communication',
                    eval: 'sociable',
                    amount: 90,
                },
                {
                    name: 'Management of Time and Resources',
                    eval: 'meticulous',
                    amount: 85,
                },
                {
                    name: 'Public Speaking and Tutoring',
                    eval: 'confident',
                    amount: 75,
                },
                {
                    name: 'Writing and Lectoring',
                    eval: 'orderly',
                    amount: 75,
                }
            ],

            hardSkillItems: [
                {
                    name: 'Programming (Java, C#, Python, Dart)',
                    eval: 'main activity',
                    amount: 98,
                },
                {
                    name: 'Unity Game Engine',
                    eval: 'experienced',
                    amount: 90,
                },
                {
                    name: 'Microsoft Office',
                    eval: 'experienced',
                    amount: 90,
                },
                {
                    name: 'Software Versioning (git)',
                    eval: 'advanced',
                    amount: 75,
                },
                {
                    name: 'Web Development (HTML, CSS, JS)',
                    eval: 'confident',
                    amount: 65,
                },
            ]
        },
        de: {
            experience: 'Berufserfahrung',
            skills: 'Fähigkeiten',
            softSkills: 'Soziale Kompetenzen',
            hardSkills: 'Technische Kompetenzen',
            education: 'Ausbildung',

            experienceTimepoints: [
                {
                    start: '01/2024',
                    end: 'AKTUELL',
                    title: 'Wissenschaftlicher Mitarbeiter',
                    subTitle: 'Hochschule Mittweida (Deutschland)',
                    text: [
                        'Entwicklung einer Analysesoftware unter Einsatz von Fahrzeugdaten aus Motorsport und zivilem Sektor',
                        'Nutzung von künstlicher Intelligenz zur Klassifizierung von Fahrerverhalten und -profilen',
                        'Übertragung in virtuelle Fahrszenarien und Fahrzeugkonfigurationen in Simulationssoftware'
                    ]
                },
                {
                    start: '02/2022',
                    end: '12/2023',
                    title: 'Studentische Hilfskraft (Didaktik und Verteilte Systeme)',
                    subTitle: 'Hochschule Mittweida (Deutschland)',
                    text: [
                        'Forschung im Bereich Didaktik und Bildung in einem gemeinsamen Hochschulprojekt mit Schwerpunkt auf Digital Skills',
                        'Planung und Umsetzung einer lernspezifischen Anwendung zur Verbesserung von Digital Skills',
                        'Verschiedene Aufgaben wie Aufbau verteilter Systeme, Entwicklung von Webdiensten und Frontend-Anwendungen'
                    ]
                },
                {
                    start: '02/2022',
                    end: '05/2022',
                    title: 'Praktikum',
                    subTitle: 'Institut für Mittelstandskooperation Mittweida',
                    text: [
                        'Aktive Entwicklung einer mobilen Anwendung als täglicher Begleiter an der Universität',
                        'Integration forschungsbezogener Funktionen und Schnittstellen',
                        'Durchführung von Evaluationen zur Verbesserung der Anwendung',
                        'Forschung zur Konzeption von E-Learning-Aktivitäten'
                    ]
                },
                {
                    start: '02/2021',
                    end: '07/2021',
                    title: 'Studentische Hilfskraft (Datenbanksysteme)',
                    subTitle: 'Hochschule Mittweida (Deutschland)',
                    text: [
                        'Mitarbeiter für die Konzeption, Implementierung und Wartung eines Dienstes mit entsprechenden Benutzeroberfläche',
                        'Hauptsächlich Fokus auf Frontend-Entwicklung'
                    ]
                },
                {
                    start: '01/2020',
                    end: '12/2020',
                    title: 'Online-Tutor',
                    subTitle: 'Easy-Tutor',
                    text: [
                        'Persönlicher Tutor für Kinder, Jugendliche und junge Erwachsene.',
                        'Hauptthemen: Informatik, Mathematik'
                    ]
                },
                {
                    start: '08/2019',
                    end: '09/2019',
                    title: 'Praktikum (Dienstleistungen der Programmierung)',
                    subTitle: 'itelligence Global Managed Services',
                    text: [
                        'Einarbeitung in das SAPUI5-Framework sowie unterstützende Tools (Git, WebIDE, GitLab)',
                        'Implementierung spezifischer Realweltprojekte unter Verwendung des SAPUI5-Frameworks'
                    ]
                },
            ],

            educationTimepoints: [
                {
                    start: '10/2022',
                    end: 'AKTUELL',
                    title: 'Medieninformatik und Interaktive Unterhaltung (M.Sc.)',
                    subTitle: 'Hochschule Mittweida (Deutschland)',
                    text: [
                        'Fokus auf der Entwicklung von Anwendungen und komplexen Systemen',
                        'Projekte unter interdisziplinärer Zusammenarbeit',
                        'Berührungspunkte mit wissenschaftlicher Arbeit und anderen Themen wie KI oder statistischer Analyse'
                    ]
                },
                {
                    start: '10/2019',
                    end: '09/2022',
                    title: 'Medieninformatik und Interaktive Unterhaltung (B.Sc.)',
                    subTitle: 'Hochschule Mittweida (Deutschland)',
                    text: [
                        'Durchschnittsnote: 1.1',
                        'Fokus auf Spieleentwicklung, Softwaretechnik sowie interdisziplinäre Zusammenarbeit',
                        'Persönlicher Fokus auf Themen der Informatik',
                        'Berührungspunkte mit kreativen Studienrichtungen wie Game Design'
                    ]
                },
                {
                    start: '09/2011',
                    end: '07/2019',
                    title: 'Abitur (Deutsche Hochschulzugangsberechtigung)',
                    subTitle: 'Philipp-Melanchthon-Gymnasium Bautzen',
                    text: [
                        'Durchschnittsnote: 1.4',
                        'Teilnahme an mehreren Tutoring-Programmen sowie Organisationsaktivitäten, wie unserem Abschlussprogramm.'
                    ]
                }
            ],

            softSkillItems: [
                {
                    name: 'Teamarbeit',
                    eval: 'sehr kooperativ',
                    amount: 98,
                },
                {
                    name: 'Kommunikation',
                    eval: 'gesellig',
                    amount: 90,
                },
                {
                    name: 'Zeit- und Ressourcenmanagement',
                    eval: 'gewissenhaft',
                    amount: 85,
                },
                {
                    name: 'Öffentliches Auftreten und Tutoring',
                    eval: 'selbstbewusst',
                    amount: 75,
                },
                {
                    name: 'Schreiben und Lektorieren',
                    eval: 'ordentlich',
                    amount: 75,
                }
            ],

            hardSkillItems: [
                {
                    name: 'Programmierung (Java, C#, Python, Dart)',
                    eval: 'Hauptaktivität',
                    amount: 98,
                },
                {
                    name: 'Unity Game Engine',
                    eval: 'erfahren',
                    amount: 90,
                },
                {
                    name: 'Microsoft Office',
                    eval: 'erfahren',
                    amount: 90,
                },
                {
                    name: 'Softwareversionierung (Git)',
                    eval: 'fortgeschritten',
                    amount: 75,
                },
                {
                    name: 'Webentwicklung (HTML, CSS, JS)',
                    eval: 'selbstsicher',
                    amount: 65,
                },
            ]
        }
    }
}