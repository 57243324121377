<script>
import InfoCard from "@/components/InfoCard.vue";

export default {
  components: {InfoCard},
}
</script>

<template>
  <div class="container-fluid start-panel">
    <div class="row">
      <div class="px-5 col-lg-6 align-self-center">

        <div class="main-heading">
          <h2 class="text-primary">{{ $t('home.subheading') }}</h2>
          <h1>{{ $t('home.heading') }}</h1>
        </div>

        <div class="button-group">
          <router-link to="book">
            <button type="button" class="btn btn-lg btn-primary my-1">{{ $t('home.buttonBook') }}</button>
          </router-link>

          <router-link to="portfolio">
            <button type="button" class="btn btn-lg btn-primary mx-1 my-1">{{ $t('home.buttonPortfolio') }}</button>
          </router-link>

          <button type="button" class="btn btn-lg btn-secondary my-1" onclick="window.location.href='#philosophy'">{{ $t('home.philosophy') }}</button>
        </div>

      </div>
      <div class="col-lg-6 bg-primary">
        <img src="./assets/portrait-stay.png" class="mt-2" loading="lazy" alt="Max Schlosser">
      </div>
    </div>
  </div>

  <div class="container-fluid mt-5 explanation" id="philosophy">
    <h1 class="text-center my-5">{{ $t("home.philosophy") }}</h1>

    <div class="row justify-content-center">
      <InfoCard :title="$t('home.cards.love.title')" :text="$t('home.cards.love.text')" icon="fa-smile-beam"/>
      <InfoCard :title="$t('home.cards.learn.title')" :text="$t('home.cards.learn.text')" icon="fa-graduation-cap"/>
      <InfoCard :title="$t('home.cards.share.title')" :text="$t('home.cards.share.text')" icon="fa-chalkboard-teacher"/>
    </div>
  </div>
</template>

<style scoped>
* {
  text-align: left;
}

@media only screen and (max-width: 991px) {
  .start-panel .button-group {
    margin-bottom: 3rem;
  }

  .start-panel .col-lg-6 img {
    max-width: 90vw;
  }
}

.start-panel {
  font-family: 'Poppins', sans-serif;
}

.start-panel img {
  margin: auto;
  display: block;
  max-height: 100vh;
}

.button-group {
  margin: auto;
  flex-direction: row;
  justify-content: center;
}
</style>