<script>
export default {
  name: "NotFoundPage"
}
</script>

<template>
  <div class="centered-container">
    <h1>404</h1>
    <h2>{{ $t('nav.notFound') }}</h2>
  </div>
</template>

<style scoped>
.centered-container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  font-size: 60pt;
}

h2 {
  font-size: 30pt;
}
</style>