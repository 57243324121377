<script>

import {defineComponent} from "vue";
import InfoCard from "@/components/InfoCard.vue";

export default defineComponent({
  components: {InfoCard},
  computed: {
    translatedCards() {
      return this.$t('book.cards');
    }
  }
})
</script>

<template>
  <div id="book">
    <div class="container-fluid start-panel">
      <div class="row">
        <div class="col-lg-6 d-flex justify-content-center py-3">
          <img src="./assets/book-3d.png" class="mx-auto book-img" loading="lazy" alt="Book">
        </div>

        <div class="col-lg-6 px-5 align-self-center book-section">
          <div class="main-heading">
            <h2 class="text-primary">{{$t('book.subHeading')}}</h2>
            <h1>{{$t('book.heading')}}</h1>
          </div>

          <p class="my-5">{{$t('book.mainText')}}</p>

          <button type="button" class="btn btn-lg btn-primary mx-1"
                  onclick="window.open('https://www.rheinwerk-verlag.de/spieleentwicklung-mit-unity-das-umfassende-handbuch/', '_blank')">{{$t('book.buttonRheinwerk')}}</button>

          <div class="button-group my-3">
            <button type="button" class="btn btn-lg btn-secondary mx-1 my-1"
                    onclick="window.open('https://www.amazon.de/dp/3836295393', '_blank')">{{$t('book.buttonAmazon')}}</button>

            <button type="button" class="btn btn-lg btn-secondary mx-1 my-1"
                    onclick="window.open('https://www.thalia.de/shop/home/artikeldetails/A1070283958', '_blank')">{{$t('book.buttonThalia')}}</button>

            <button type="button" class="btn btn-lg btn-secondary mx-1 my-1"
                    onclick="window.open('https://www.hugendubel.de/de/buch_gebunden/max_schlosser-spieleentwicklung_mit_unity-47207821-produkt-details.html',
                     '_blank')">{{$t('book.buttonHugendubel')}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-5 explanation">
      <h1 class="text-center my-5">{{$t('book.mainAspects')}}</h1>

      <div class="row justify-content-center mb-5">
        <InfoCard :title="$t('book.cards.theory.title')" :text="$t('book.cards.theory.text')" icon="fa-brain"/>
        <InfoCard :title="$t('book.cards.practice.title')" :text="$t('book.cards.practice.text')" icon="fa-pencil"/>
        <InfoCard :title="$t('book.cards.community.title')" :text="$t('book.cards.community.text')" icon="fa-users"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.book-section p {
  font-size: 15pt;
}

.book-img {
  height: 80vh;
}

@media only screen and (max-width: 600px) {
  .book-img {
    height: auto;
    width: 80vw;
  }
}
</style>