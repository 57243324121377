export default {
    messages: {
        en: {
            entries: {
                book: {
                    title: 'Book: Unity',
                    text: 'German comprehensive guide to getting started with game development in Unity. Covers all the basics, but also advanced knowledge of the engine and the C# programming language.',
                    extra:'Time: 12 months\nSole author, ca. 930 pages'
                },
                studisq: {
                    title: 'StudiSQ',
                    text: 'Motivational learning app used in higher education at universities in Germany.',
                    extra: 'Role: Frontend Developer'
                },
                deinplugin: {
                    title: 'DeinPlugin',
                    text: 'Platform for offering free, open-source plugins. Seamless integration to various features of GitHub.',
                    extra: 'Team coordination and frontend development'
                },
                hsmwmobil: {
                    title: 'HSMWmobil',
                    text: 'Full remake of our University everyday app used by about 6,000 people (09/22) with an extended set of features. Developed using Flutter and Dart.',
                    extra: 'Role: Lead Developer'
                },
                ampguard: {
                    title: 'Ampguard',
                    text: 'Game developed over two semesters with the entire matriculation. Available on Steam for free.',
                    extra:'Time: 10 months\nRole: Technical Lead\n\n10.000+ Downloads on Steam'
                },
                schloool: {
                    title: 'Schloool',
                    text: 'My German YouTube channel which teaches the basics of game development. Also deals with my life in general.'
                },
                bivieh: {
                    title: 'BiVieh',
                    text: 'My first German YouTube channel which teaches the basics of the Java programming language with the practical example of Minecraft server plugins.'
                },
                webcongress: {
                    title: 'Webentwicklerkongress',
                    text: 'Lecturer on a two-day workshop dealing with various topics around web development.',
                    extra: 'Not planned for 2021 and following years\n(Currently under maintenance)'
                },
                lokallabore: {
                    title: 'Lokallabore Sachsen',
                    text: 'Lecturer for a workshop teaching the basics of developing games for children and teenagers.',
                }
            },
        },
        de: {
            entries: {
                book: {
                    title: 'Buch: Unity',
                    text: 'Umfassendes Handbuch für den Einstieg in die Spieleentwicklung mit Unity. Deckt alle Grundlagen ab, aber auch fortgeschrittenes Wissen über die Engine und die Programmiersprache C#.',
                    extra: 'Dauer: 12 Monate\nAlleiniger Autor, ca. 930 Seiten'
                },
                studisq: {
                    title: 'StudiSQ',
                    text: 'Motivations-Lern-App, die in der höheren Bildung an deutschen Universitäten verwendet wird.',
                    extra: 'Rolle: Frontend-Entwickler'
                },
                deinplugin: {
                    title: 'DeinPlugin',
                    text: 'Plattform zum Anbieten von kostenlosen, Open-Source-Plugins. Nahtlose Integration in verschiedene Funktionen von GitHub.',
                    extra: 'Teamkoordination und Frontend-Entwicklung'
                },
                hsmwmobil: {
                    title: 'HSMWmobil',
                    text: 'Komplette Neugestaltung unserer Unialltags-App, die von etwa 6.000 Personen verwendet wird (09/22) mit erweitertem Funktionsumfang. Entwickelt mit Flutter und Dart.',
                    extra: 'Rolle: Leitender Entwickler'
                },
                ampguard: {
                    title: 'Ampguard',
                    text: 'Spiel, das über zwei Semester mit der gesamten Immatrikulation entwickelt wurde. Kostenlos auf Steam erhältlich.',
                    extra: 'Dauer: 10 Monate\nRolle: Technical Lead\n\n10.000+ Downloads auf Steam'
                },
                schloool: {
                    title: 'Schloool',
                    text: 'Mein deutscher YouTube-Kanal, der die Grundlagen der Spieleentwicklung lehrt. Beschäftigt sich auch mit verschiedenen Themen rund um mein Leben.'
                },
                bivieh: {
                    title: 'BiVieh',
                    text: 'Mein erster deutscher YouTube-Kanal, der die Grundlagen der Java-Programmiersprache mit dem praktischen Beispiel von Minecraft-Server-Plugins lehrt.'
                },
                webcongress: {
                    title: 'Webentwicklerkongress',
                    text: 'Dozent bei einem zweitägigen Workshop zu verschiedenen Themen rund um die Webentwicklung.',
                    extra: 'Nicht geplant für 2021 und folgende Jahre\n(Aktuell in Wartung)'
                },
                lokallabore: {
                    title: 'Lokallabore Sachsen',
                    text: 'Dozent für einen Workshop, der die Grundlagen der Spieleentwicklung für Kinder und Jugendliche vermittelt.'
                }
            }
        }
    },
}