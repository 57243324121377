export default {
    messages: {
        en: {
            home: 'Home',
            book: 'My Book',
            portfolio: 'Portfolio',
            resume: 'Résumé',
            contact: 'Contact',
            notFound: 'This page does not exist.'
        },
        de: {
            home: 'Start',
            book: 'Mein Buch',
            portfolio: 'Portfolio',
            resume: 'Résumé',
            contact: 'Kontakt',
            notFound: 'Diese Seite existiert nicht.'
        }
    }
}