export default {
    messages: {
        en: {
            address: 'Address',
            mailHeading: 'Contact Mail Address',
            mailInfo: 'Business / Cooperation Inquiries:',
            tosTitle: 'Website Terms and Conditions of Use',
        },
        de: {
            address: 'Addresse',
            mailHeading: 'Kontakt Mail-Adresse',
            mailInfo: 'Geschäftliche Anfragen oder Kooperation:',
            tosTitle: 'Nutzungsbedingungen der Website',
        }
    }
}