<script>
</script>

<template>
  <div id="resume">
    <h1 class="text-center normal-heading">{{ $t('resume.experience') }}</h1>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="container-fluid">
            <div class="main-timeline">
              <div v-for="time in $tm('resume.experienceTimepoints')" :key="time.id">
                <div class="timeline">
                  <div class="icon"></div>
                  <div class="date-content">
                    <div class="date-outer">
                      <span class="date">
                        <span class="month">{{ time.end }}</span> <br>
                        <span class="month month-start">{{ time.start }}</span>
                      </span>
                    </div>
                  </div>
                  <div class="timeline-content">
                    <h5 class="title">{{time.title}}</h5>
                    <h6 class="subtitle">{{time.subTitle}}</h6>
                    <ul class="description">
                      <div v-for="text in time.text" :key="text.id">
                        <li>{{ text }}</li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h1 class="text-center normal-heading">{{ $t('resume.education') }}</h1>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="container-fluid">
            <div class="main-timeline">
              <div v-for="time in $tm('resume.educationTimepoints')" :key="time.id">
                <div class="timeline">
                  <div class="icon"></div>
                  <div class="date-content">
                    <div class="date-outer">
                                            <span class="date">
                                                <span class="month">{{time.end}}</span>
                                                <br>
                                                <span class="month month-start">{{time.start}}</span>
                                            </span>
                    </div>
                  </div>
                  <div class="timeline-content">
                    <h5 class="title">{{time.title}}</h5>
                    <h6 class="subtitle">{{time.subTitle}}</h6>

                    <ul class="description">
                      <div v-for="text in time.text" :key="text.id">
                        <li>{{ text }}</li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h1 class="text-center normal-heading">{{ $t('resume.skills') }}</h1>
    <div class="container-fluid mb-5">
      <div class="row">

        <div class="col-lg-6">
          <h2 class="text-center mt-5">{{ $t('resume.softSkills') }}</h2>

          <div v-for="item in $tm('resume.softSkillItems')" class="progress-item mt-4" :key="item.id">
            <span class="title">{{ item.name }}</span>
            <div class="progress">
              <div class="progress-bar" role="progressbar" v-bind:style="'width: ' + item.amount + '%'"></div>
            </div>
            <span>{{ item.eval }}</span>
          </div>
        </div>

        <div class="col-lg-6">
          <h2 class="text-center mt-5">{{ $t('resume.hardSkills') }}</h2>
          <div v-for="item in $tm('resume.hardSkillItems')" class="progress-item mt-4" :key="item.id">
            <span class="title">{{ item.name }}</span>
            <div class="progress">
              <div class="progress-bar" role="progressbar" v-bind:style="'width: ' + item.amount + '%'"></div>
            </div>
            <span>{{ item.eval }}</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>
.main-timeline h1 {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.main-timeline h5 {
  font-weight: 100;
}

.main-timeline h6 {
  font-weight: bold;
}

h5, h6 {
  font-size: 2rem;
}

.main-timeline {
  position: relative;
}

.main-timeline:before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background: var(--bs-primary);
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0
}

.main-timeline .timeline {
  margin-bottom: 40px;
  position: relative
}

.main-timeline .timeline:after {
  content: "";
  display: block;
  clear: both;
}

.main-timeline .icon:after {
  border: 2px solid var(--bs-primary);
}

.main-timeline .date-content {
  width: 50%;
  float: left;
  margin-top: 22px;
  position: relative
}

.main-timeline .date-content:before {
  content: "";
  width: 36.5%;
  height: 2px;
  background: var(--bs-primary);
  margin: auto 0;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0
}

.main-timeline .date-outer {
  width: 125px;
  height: 125px;
  font-size: 16px;
  text-align: center;
  margin: auto;
  z-index: 1
}

.main-timeline .date-outer:before,
.main-timeline .date-outer:after {
  content: "";
  width: 125px;
  height: 125px;
  margin: 0 auto;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.33s ease-out 0s
}

.main-timeline .date-outer:before {
  background: #fff;
  border: 2px solid rgba(2, 117, 216, 0.92);
  left: -6px
}

.main-timeline .date-outer:after {
  border: 2px solid var(--bs-primary);
  left: 6px
}

.main-timeline .timeline:hover .date-outer:before {
  left: 6px
}

.main-timeline .timeline:hover .date-outer:after {
  left: -6px
}

.main-timeline .date {
  width: 100%;
  margin: auto;
  position: absolute;
  top: 27%;
  left: 0
}

.main-timeline .month {
  font-size: 18px;
  font-weight: 700
}

.main-timeline .month-start {
  color: #c6c6c6;
  font-weight: 600;
}

.main-timeline .timeline-content {
  width: 50%;
  padding: 20px 0 20px 50px;
  float: left;
}

.main-timeline .title {
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 24px;
}

.main-timeline .subtitle {
  font-size: 1.2rem;
  font-weight: 500;
}

.main-timeline .description {
  margin-bottom: 0;
  white-space: pre-wrap;
}

.main-timeline .timeline:nth-child(2n) .date-content {
  float: right
}

.main-timeline .timeline:nth-child(2n) .date-content:before {
  left: 10px
}

.main-timeline .timeline:nth-child(2n) .timeline-content {
  text-align: right;
}

.progress-item {
  width: 60%;
  margin: auto;
  font-size: 1.2rem;
}

.progress-item span {
  font-weight: 300;
}

.progress-item .title {
  font-weight: 600;
}

@media only screen and (max-width: 991px) {
  .main-timeline:before {
    width:0;
  }

  .main-timeline .timeline-content ul li {
    text-align: left;
  }

  .main-timeline .date {
    width: 10%;
    margin: auto;
    position: absolute;
    top: 27%;
    left: 0
  }

  .main-timeline .date-content {
    margin-top: 0.5rem;
    margin-left: 1rem;
    width: 100%;
    display: block;
    text-align: center;
  }

  .main-timeline .date-content:before {
    width: 0;
  }

  .main-timeline .date-outer {
    display: inline-block;
  }

  .main-timeline .date-outer::before {
    right: 100vw;
    width: 140px;
    height: 140px;

    border-radius: 30px;
    border-width: 3px;
  }

  .main-timeline .date-outer::after {
    display: none;
  }

  .main-timeline .date-content .month {
    font-size: 1.6rem;
    margin-left: 0.5rem;
  }

  .main-timeline .title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    width: 90vw;
    line-height: 100%;
  }

  .main-timeline .subtitle {
    font-size: 1.6rem;
    width: 195%;
    margin-bottom: 0.75rem;
  }

  .description {
    width: 90vw
  }

  .description li {
    font-size: 1.3rem !important;
    padding-right: 1.5rem !important;
  }

  .main-timeline .timeline-content {
    padding: 0;
  }

  .progress-item {
    width: 90%;
    font-size: 1.2rem;
  }
}
</style>