export default {
    messages: {
        en: {
            imprint: 'Imprint',
            love: 'Made with love',
        },
        de: {
            imprint: 'Impressum',
            love: 'Mit Liebe gemacht',
        }
    }
}