export default {
    messages: {
        en: {
            subHeading: 'MY BIGGEST PROJECT TO DATE',
            heading: 'My Book',
            mainText: 'In cooperation with Rheinwerk Verlag, I have written a reference book on game development with Unity. The book is written in German and contains all the important components you need to master the engine: From getting started with the useful tools, to writing scripts or building numerous mini-games in countless exercises, this book is designed to help all readers to master game development from scratch. For this reason, advanced topics are not shied away from either.',
            buttonRheinwerk: 'Buy from Rheinwerk',
            buttonAmazon: 'Buy on Amazon',
            buttonThalia: 'Buy on Thalia',
            buttonHugendubel: 'Buy on Hugendubel',
            mainAspects: 'Main Aspects',
            cards: {
                theory: {
                    title: 'Theory',
                    text: 'The book conveys the most important basics that you will need later to implement your own ideas. Whether physics, UI, input or programming with C#: The book should answer as many questions as paper allows!',
                },
                practice: {
                    title: 'Practice makes perfect!',
                    text: 'I am convinced that you can only learn a topic as exciting as game development with a lot of practice. For this reason, the book contains many clear examples and exercises along with hints that might lead you to a successful solution.',
                },
                community: {
                    title: 'Community',
                    text: 'Learning together is not only a lot of fun, but also more effective! The book provides a way that readers can use to network with each other. In this way, learning groups can be formed - or perhaps you can find fellow campaigners to work on your first real game.',
                },
            },
        },
        de: {
            subHeading: "MEIN BISHER GRÖßTES PROJEKT",
            heading: "Mein Buch",
            mainText: "In Zusammenarbeit mit dem Rheinwerk Verlag habe ich ein Handbuch über die Spieleentwicklung mit Unity geschrieben. Das Buch ist in deutscher Sprache verfasst und enthält alle wichtigen Komponenten, die benötigt werden, um die Engine zu beherrschen: Vom Einstieg mit den vielen nützlichen Tools über das Schreiben von Scripts bis hin zum Erstellen zahlreicher Minispiele in unzähligen Übungen ist dieses Buch darauf ausgelegt, allen zu helfen, die Spieleentwicklung von Grund auf zu meistern. Aus diesem Grund werden auch fortgeschrittene Themen nicht gescheut.",
            buttonRheinwerk: "Bei Rheinwerk kaufen",
            buttonAmazon: "Bei Amazon kaufen",
            buttonThalia: 'Bei Thalia kaufen',
            buttonHugendubel: 'Bei Hugendubel kaufen',
            mainAspects: "Wichtiges",
            cards: {
                theory: {
                    title: "Theorie",
                    text: "Das Buch vermittelt die wichtigsten Grundlagen, die später benötigt werden, um eigene Ideen umzusetzen. Ob Physik, UI, Input oder Programmierung mit C#: Das Buch soll so viele Fragen beantworten, wie es Papier erlaubt!",
                },
                practice: {
                    title: "Übung macht den Meister!",
                    text: "Ich bin überzeugt, dass ein so spannendes Thema wie die Spieleentwicklung nur mit viel Übung erlernt werden kann. Aus diesem Grund enthält das Buch viele Beispiele und Übungen mit Tipps, die (hoffentlich) zu einer erfolgreichen Lösung führen.",
                },
                community: {
                    title: "Gemeinschaft",
                    text: "Gemeinsam Lernen macht nicht nur viel Spaß, sondern ist auch effektiver! Das Buch bietet eine Möglichkeit, wie Leser miteinander in Kontakt treten können. So können Lerngruppen gebildet werden - oder man sucht Mitstreiter, um an einem ersten echten Spiel zu arbeiten.",
                }
            }
        }
    },
}