import Vuex from 'vuex';

export default new Vuex.Store({
    state: {
        appLanguage: localStorage.getItem("appLanguage") || 'en'
    },
    getters: {
        getAppLanguage: (state) => {
            return state.appLanguage;
        }
    },
    mutations: {
        setAppLanguage(state, language) {
            state.appLanguage = language;
            localStorage.setItem("appLanguage", language);
        }
    }
})