export default {
    messages: {
        en: {
            heading: 'I\'m Max.',
            subheading: 'DEVELOPER & TUTOR',
            buttonPortfolio: 'My Portfolio',
            philosophy: 'My Philosophy',
            buttonBook: 'My Book',
            cards: {
                love: {
                    title: 'Do what you love!',
                    text: 'Ever since touching my first book on the topic, programming became my favorite hobby. I have neither doubts nor any regrets that I chose my path that early: I am in nothing but love with the things I do every single day.',
                },
                learn: {
                    title: 'Never stop learning!',
                    text: 'Today, I study Media Informatics and Interactive Entertainment at the University of Applied Sciences in Mittweida (Germany). I am mostly developing interactive applications and love both theory and team practice. I also dive into research.',
                },
                share: {
                    title: 'Share knowledge!',
                    text: 'Inspiring both children and adults to start getting into computer science and especially programming became my personal concern. That is why I have engaged in multiple projects dealing with effective education strategies in the given topics.',
                }
            },
        },
        de: {
            heading: "Ich bin Max.",
            subheading: "ENTWICKLER & TUTOR",
            buttonPortfolio: "Mein Portfolio",
            philosophy: "Meine Philosophie",
            buttonBook: "Mein Buch",
            cards: {
                love: {
                    title: "Tue, was du liebst!",
                    text: "Seitdem ich mein erstes Buch zum Thema in die Hand genommen habe, ist Programmieren mein liebstes Hobby geworden. Ich bereue nicht, dass ich meinen Weg so früh eingeschlagen habe: Ich liebe die Dinge, die ich Tag für Tag tue."
                },
                learn: {
                    title: "Lerne jeden Tag!",
                    text: "Heute studiere ich Medieninformatik und Interaktives Entertainment an der Hochschule Mittweida. Ich entwickle hauptsächlich interaktive Anwendungen und liebe sowohl die Theorie als auch die praktische Arbeit im Team. Gleichzeitig steige ich in den Bereich der Forschung ein."
                },
                share: {
                    title: "Teile Wissen!",
                    text: "Andere dazu zu inspirieren, sich mit Informatik und insbesondere der Programmierung zu beschäftigen, ist mir ein persönliches Anliegen geworden. Deshalb habe ich mich in mehreren Projekten engagiert, die sich mit effektiven Bildungsstrategien in diesen Themenbereichen befassen."
                }
            }
        }
    },
}