export default {
    messages: {
        en: {
            heading: 'Contact',
            info: 'Whether it is about cooperation requests, assignments or general suggestions: Feel free to contact me whenever you want. I will answer as soon as I can.',
            name: 'Your name',
            mail: 'Your e-mail address',
            subject: 'Subject',
            message: 'Message',
            send: 'Send',
            sent: 'Thank you for contacting me. I will answer as soon as possible.'
        },
        de: {
            heading: 'Kontakt',
            info: 'Ob es um Kooperationsanfragen, Aufträge oder allgemeine Vorschläge geht: Kontaktieren Sie mich jederzeit. Ich werde so schnell wie möglich antworten.',
            name: 'Ihr Name',
            mail: 'Ihre E-Mail-Adresse',
            subject: 'Betreff',
            message: 'Nachricht',
            send: 'Senden',
            sent: 'Vielen Dank für Ihre Kontaktaufnahme. Ich werde so schnell wie möglich antworten.'
        }
    }
}