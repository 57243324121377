import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import { createI18n } from 'vue-i18n';
import store from './store';
import App from './App.vue';

import HomePage from "@/HomePage.vue";
import PortfolioPage from "@/PortfolioPage.vue";
import ResumePage from "@/ResumePage.vue";
// import ContactPage from "@/ContactPage.vue";
import BookPage from "@/BookPage.vue";
import ImprintPage from "@/ImprintPage.vue";
import NotFoundPage from "@/NotFoundPage.vue";

import nav from "@/localizations/nav";
import footer from "@/localizations/footer";
import home from "@/localizations/home";
import portfolio from "@/localizations/portfolio";
import resume from "@/localizations/resume";
import contact from "@/localizations/contact";
import book from "@/localizations/book";
import imprint from "@/localizations/imprint";

const routes = [
    { path: '/', name: 'Home', component: HomePage },
    { path: '/portfolio', name: 'Portfolio', component: PortfolioPage },
    { path: '/resume', name: 'Résumé', component: ResumePage },
    // { path: '/contact', name: 'Contact', component: ContactPage },
    { path: '/book', name: 'Book', component: BookPage },
    { path: '/imprint', name: 'Imprint', component: ImprintPage },
    { path: '/:pathMatch(.*)*', name: '404', component: NotFoundPage },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

const i18n = createI18n({
    locale: store.getters.getAppLanguage,
    messages: {
        en: {
            nav: nav.messages.en,
            footer: footer.messages.en,
            home: home.messages.en,
            portfolio: portfolio.messages.en,
            resume: resume.messages.en,
            contact: contact.messages.en,
            book: book.messages.en,
            imprint: imprint.messages.en,
        },
        de: {
            nav: nav.messages.de,
            footer: footer.messages.de,
            home: home.messages.de,
            portfolio: portfolio.messages.de,
            resume: resume.messages.de,
            contact: contact.messages.de,
            book: book.messages.de,
            imprint: imprint.messages.de,
        },
    }
});

const app = createApp(App)
app.use(router)
app.use(i18n)

app.mount('#app')