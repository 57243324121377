<script>
export default {
  name: 'AppFooter',
  methods: {
    getFullYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<template>
  <footer class="text-center text-lg-start bg-light text-muted">

    <div class="btn-group mx-auto d-flex justify-content-center">
      <a class="mx-3" href="https://www.instagram.com/schloool/" target="_blank">
        <i class="fab fa-instagram text-primary h3 text-center"></i>
      </a>

      <a class="mx-3" href="https://x.com/schloool" target="_blank">
        <i class="fab fa-x-twitter text-primary h3 text-center"></i>
      </a>

      <a class="mx-3" href="https://www.linkedin.com/in/max-schlosser-1657131a6/" target="_blank">
        <i class="fab fa-linkedin-in text-primary h3 text-center"></i>
      </a>

      <a class="mx-3" href="https://github.com/schloool" target="_blank">
        <i class="fab fa-github text-primary h3 text-center"></i>
      </a>

      <a class="mx-3" href="mailto:kontakt@max-schlosser.de">
        <i class="fas fa-envelope text-primary h3 text-center"></i>
      </a>
    </div>

    <h5 class="mt-4 text-center text-primary">Max Schlosser</h5>

    <div class="text-center">
      <router-link to="imprint" class="imprint-link">
        {{$t('footer.imprint')}}
      </router-link>
    </div>

    <h6 class="mt-3 text-center text-primary">{{$t('footer.love')}} © Max Schlosser, 2021 - {{getFullYear()}}</h6>
  </footer>
</template>

<style scoped>
footer {
  padding: 2rem 0;
  border-top: 1px solid var(--bs-primary);
}

@media only screen and (max-width: 991px) {
  footer, footer button {
    font-size: 2rem !important;
  }

  footer i {
    font-size: 25pt !important;
  }

  .imprint-link {
    font-size: 16pt;
  }
}
</style>