<script>
import store from './store';

export default {
  data() {
    return {
      isNavOpen: false,
    };
  },
  methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
      let nav = document.getElementById('navbarSupportedContent');
      if (this.isNavOpen) {
        nav.classList.add('slide-in');
        nav.classList.remove('slide-out');
      } else {
        nav.classList.add('slide-out');
        nav.classList.remove('slide-in');
      }
    },
    changeLanguage(language) {
      this.$i18n.locale = language;
      store.commit('setAppLanguage', language);
    },
    initializeLanguage() {
      this.$i18n.locale = store.getters.getAppLanguage;
    },
  },
  created() {
    this.initializeLanguage();
  },
};
</script>

<template>
  <nav class="navbar navbar-expand-lg navbar-static-top navbar-light mx-3">
    <router-link to="/">
      <img src="./assets/favicon.png" class="nav-img" alt="Logo">
    </router-link>

    <button class="navbar-toggler mx-3" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto mx-4">
        <li class="nav-item mx-3">
          <router-link to="/">{{$t("nav.home")}}</router-link>
        </li>
        <li class="nav-item mx-3">
          <router-link to="/book">{{$t("nav.book")}}</router-link>
        </li>
        <li class="nav-item mx-3">
          <router-link to="/portfolio">{{$t("nav.portfolio")}}</router-link>
        </li>
        <li class="nav-item mx-3">
          <router-link to="/resume">{{$t("nav.resume")}}</router-link>
        </li>
      </ul>
      <div class="lang d-flex">
        <button @click="changeLanguage('en')" :class="{ 'active': $i18n.locale === 'en', 'inactive': $i18n.locale === 'de' }" class="lang-btn">
          <img src="./assets/english.png" alt="English" />
          English
        </button>
        <button @click="changeLanguage('de')" :class="{ 'active': $i18n.locale === 'de', 'inactive': $i18n.locale === 'en' }" class="lang-btn">
          <img src="./assets/german.png" alt="German" />
          Deutsch
        </button>
      </div>
    </div>
  </nav>
</template>


<style scoped>
.navbar-toggler-icon {
  font-size: 20pt;
}

.nav-img {
  max-width: 3.5rem;
  background-color: var(--bs-primary);
  padding: 10px;
  border-radius: 40px;
}

router-link {
  text-decoration: none;
  color: red;
}

.lang {
  display: flex;
  align-items: center;
}

.lang button {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.lang img {
  height: 30px;
  margin-right: 5px;
}

.lang .active {
  color: #000;
  font-weight: bold;
}

.lang .inactive {
  color: #808080;
}

@media (max-width: 767px) {
  .navbar {
    margin-bottom: 2rem;
  }

  .navbar-nav {
    flex-direction: column;
    text-align: center;
  }

  .navbar-nav .nav-item {
    margin-bottom: 10px;
  }

  .navbar-collapse {
    text-align: center;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .slide-in {
    max-height: 40vh;
    opacity: 1;
  }

  .slide-out {
    max-height: 0;
    opacity: 0;
  }

  .lang {
    justify-content: center;
    margin-top: 10px;
  }

  .lang button {
    margin: 30px;
  }
}

@media (min-width: 768px) {
  .lang {
    position: absolute;
    right: 0;
  }
}
</style>

